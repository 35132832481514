import { atom, Provider, useAtom } from "jotai";
import { Suspense } from "react";
import Confetti from "react-confetti";
import { useEffectOnce, useInterval } from "react-use";
import useWindowSize from "react-use/lib/useWindowSize";
import ShortUniqueId from "short-unique-id";
import {
  currentPlayerAtom,
  isMyTurn,
  nextTurnAtom,
  selectSquareAtom,
  squaresAtom,
  updateStatesAtom,
  UUIDAtom,
  winnerAtom,
} from "./atoms";
import "./styles.css";

const statusAtom = atom((get) => {
  return get(winnerAtom)
    ? `Winner: ${get(winnerAtom)}`
    : get(squaresAtom).every(Boolean)
    ? `Scratch`
    : `Next player: ${get(nextTurnAtom)}`;
});

function Square({ i }: { i: number }) {
  const [squares, selectSquare] = useAtom(selectSquareAtom);
  const [update, updater] = useAtom(updateStatesAtom);
  const [isTurn] = useAtom(isMyTurn);
  useInterval(() => {
    console.log("Pushing an Update");
    updater(update + 1);
  }, 4000);
  return (
    <button
      className={`square ${squares[i]} ${isTurn ? "" : "disable"}`}
      onClick={() =>
        isTurn
          ? selectSquare(i)
          : () => {
              console.log("isDisabled");
            }
      }
    >
      {squares[i]}
    </button>
  );
}

function Status() {
  const [gameStatus] = useAtom(statusAtom);
  const [UUID] = useAtom(UUIDAtom);
  const [symbol] = useAtom(currentPlayerAtom);

  return (
    <div className="status">
      <div className="message">
        {gameStatus}{" "}
        <button
          onClick={() => {
            alert("To Implement, Check console");
            console.log("Please delete the UserID from LocalStorage for now");
          }}
        >
          RESET
        </button>
      </div>
      <div>
        You are : {symbol} - UUID {UUID}
      </div>
    </div>
  );
}

function End() {
  const { width, height } = useWindowSize();
  const [gameWinner] = useAtom(winnerAtom);
  return (
    <>
      {gameWinner && (
        <Confetti
          width={width}
          height={height}
          colors={[gameWinner === "X" ? "#d76050" : "#509ed7", "white"]}
        />
      )}
    </>
  );
}
export default function App() {
  const uid = new ShortUniqueId({ dictionary: "hex" });
  const color = uid.randomUUID(6);
  const [UUID, setUUID] = useAtom(UUIDAtom);
  useEffectOnce(() => {
    if (UUID === "nothing") setUUID(color);
  });
  return (
    <Provider>
      <Suspense fallback={<h2>Updating...</h2>}>
        <div className="game">
          <h1>
            x<span>o</span>x<span>o</span>
          </h1>
          <Status />
          <div className="board">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((field) => (
              <Square key={field} i={field} />
            ))}
          </div>
        </div>
        <End />
      </Suspense>
    </Provider>
  );
}
