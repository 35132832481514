import { Atom, atom, WritableAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import ShortUniqueId from "short-unique-id";
import {
  API_ROOT,
  cellState,
  lines,
  matchLobby,
  userMetadata,
} from "./constants";

async function makeBoardUpdate(cellPos: number, id: string) {
  const request = await fetch(`${API_ROOT}?userID=${id}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ cell: cellPos }),
  });
  return request;
}

export const UUIDAtom = atomWithStorage("UserID", "nothing");
// Use this to rehydrate
export const updateStatesAtom = atom(0);

interface fetchedData {
  userMetadata: userMetadata;
  lobby: matchLobby;
}

export const lobbyDataAtom = atom<Promise<fetchedData>>(async (get) => {
  const id = get(UUIDAtom);
  const update = get(updateStatesAtom);
  console.log("Refetching");
  const response = await fetch(`${API_ROOT}?userID=${id}&update=${update}`);
  const parsed: fetchedData = await response.json();
  return parsed;
});

export const currentPlayerAtom = atom((get) => {
  const {
    userMetadata: { symbol },
  } = get(lobbyDataAtom);
  return symbol;
});

export const nextTurnAtom = atom((get) => {
  const {
    lobby: { next_player },
  } = get(lobbyDataAtom);
  return next_player;
});

export const isMyTurn = atom((get) => {
  return get(nextTurnAtom) === get(currentPlayerAtom);
});

export const squaresAtom = atom<Array<cellState | null>>((get) => {
  const {
    lobby: { state },
  } = get(lobbyDataAtom);
  return state;
});

export const winnerAtom = atom((get) => {
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (
      get(squaresAtom)[a] &&
      get(squaresAtom)[a] === get(squaresAtom)[b] &&
      get(squaresAtom)[a] === get(squaresAtom)[c]
    )
      return get(squaresAtom)[a];
  }
  return null;
});

export const selectSquareAtom = atom(
  (get) => get(squaresAtom),
  async (get, set, square: number) => {
    if (get(winnerAtom) || get(squaresAtom)[square]) return;
    const id = get(UUIDAtom);
    const currPlayer = get(currentPlayerAtom);
    const nextPlayer = get(nextTurnAtom);
    if (nextPlayer === currPlayer) await makeBoardUpdate(square, id);
    set(updateStatesAtom, get(updateStatesAtom) + 1);
  }
);
