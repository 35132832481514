const API_ROOT = "https://tic-tac-api.lunchb0ne.me/";

const lines = [[0, 1, 2], [3, 4, 5], [6, 7, 8], [0, 3, 6], [1, 4, 7], [2, 5, 8], [0, 4, 8], [2, 4, 6]] // prettier-ignore

type cellState = "X" | "O";

interface userMetadata {
  lobby_id: string;
  symbol: cellState;
}

class matchLobby {
  state: Array<cellState | null>;
  next_player: cellState;
  constructor() {
    this.state = Array(9).fill(null);
    this.next_player = "X";
  }
}

export { matchLobby, userMetadata, cellState, API_ROOT, lines };
